(function () {
    if (!!$('.who-we-are').length) {

        let windowHeight = $(window).height();
        let scrollPos = 0;
        let scrollDown = true;
        let whoWeAre = $('.who-we-are').offset().top;
        let scrollTop = 0;


        $(window).on('resize', () => {
            windowHeight = $(window).height();
        })

        $(window).on('scroll', function () {
            let st = $(this).scrollTop();
            if (st > scrollPos) {
                scrollDown = true
            } else {
                scrollDown = false
            }
            scrollPos = st;
            scrollTop = $(window).scrollTop();
            let accentTitleIndex = $('.who-we-are h4.text_accent').index() + 1;
            let accentTitleLength = $('.who-we-are h4').length;
            let scrollPosition  = scrollTop - (whoWeAre - windowHeight*(accentTitleLength - accentTitleIndex)/(accentTitleLength));
            if (scrollDown && scrollPosition > 0) {
                if ($('.who-we-are h4.text_accent').next().length) {
                    $('.who-we-are h4.text_accent').removeClass('text_accent').next().addClass('text_accent')
                }
            } else if (!scrollDown && scrollPosition < 0) {
                if ($('.who-we-are h4.text_accent').prev().length) {
                    $('.who-we-are h4.text_accent').removeClass('text_accent').prev().addClass('text_accent')
                }
            }
        })
    }
})()