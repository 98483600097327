export function fixSizes() {
    equalizeHeight($('.whitepaper h4, .whitepaper p'));
    $('.featured__slider').imagesLoaded(function () {
        equalizeHeight($('.featured__slider .slick-slide'));
    })
    if ($(window).width() < 768) {
        $('.microgrant__gallery').imagesLoaded(function () {
            equalizeHeight($('.microgrant__gallery-item'))
        });
    }
}

function equalizeHeight(elem) {
    let maxHeight = 0;
    elem.each(function (index, item) {
        maxHeight = Math.max(maxHeight, $(item).css('box-sizing')=='border-box'? $(item).innerHeight(): $(item).height())
    })
    elem.css({height: maxHeight})
}