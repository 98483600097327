(function () {

    $('.work-step__video-button').on('click', function (e) {
        e.preventDefault();
        let $thisOverlay = $(this).closest('.work-step__video-overlay');
        let video = $(this).attr('data-src');
        let vimeoWrapperId = $(this).closest('.work-step__video').find('.work-step__video-vimeo').attr('id');
        let vimeoVideoID = video.slice(-9);
        let player = vimeoPlayer(vimeoWrapperId, vimeoVideoID);
        $thisOverlay.css({'display': 'none'});
        let thisSectionTop = $(this).closest('.work-step__video').offset().top
        $(window).on('scroll', function () {
            let scrollTop = $(window).scrollTop()
            if (thisSectionTop < scrollTop) {
                player.pause()
            }
        })

    })


    function vimeoPlayer(vimeoWrapperID, vimeoVideoID) {
        let options = {
            id: vimeoVideoID,
            width: 640,
            loop: false,
            autoplay: true
        };
        return new Vimeo.Player(vimeoWrapperID, options);
        // playerVimeo.setVolume(0);
    }



})()