(function () {

    let baseUri;

    if (typeof wp != "undefined") {
        baseUri = wp.tplUrl
    } else {
        baseUri = '..'
    }

    let prevArrow = `
    <button type="button" class="slick-arrow__prev">
       <svg>
        <use xlink:href="${baseUri}/svg-sprite/sprite.svg#arrow-grey-left"></use>
      </svg>
      <span>prev</span>
    </button>
  `;

    let nextArrow = `
    <button type="button" class="slick-arrow__next">
       <svg>
        <use xlink:href="${baseUri}/svg-sprite/sprite.svg#arrow-grey-right"></use>
      </svg>
      <span>prev</span>
    </button>
  `;

    const citeSlideTotal = $('.cite__slider > div').length
    const initialSlide = getRandomInRange(0, citeSlideTotal)

    $('.cite__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        speed: 600,
        initialSlide: initialSlide
    });

    $('.projects__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        speed: 600,
        dots: true,
    });

    $('.projects__slider .slick-next').insertAfter('.projects__slider .slick-dots')
    $('.projects__slider .slick-next, .projects__slider .slick-dots').wrapAll("<div class='slider-nav'>")

    $('.featured__slider').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        speed: 600,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.similar-projects__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '29%',
        arrows: false,
        // autoplay: true,
        speed: 600,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '0',
                    centerMode: false,
                    arrows: true,
                    prevArrow,
                    nextArrow,
                }
            },
        ]
    });

    $('.single-project__gallery')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    if (slick.activeBreakpoint < window.innerWidth) {
                        return;
                    }
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: false,
            prevArrow,
            nextArrow,
            slidesToScroll: 1,
            autoplay: true,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: 'unslick'
                }
            ]
        });

    $('.work-featured__grid')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    if (slick.activeBreakpoint < window.innerWidth) {
                        return;
                    }
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: true,
            arrows: true,
            slidesToScroll: 1,
            // autoplay: true,
            responsive: [
                {
                    breakpoint: 768,
                    slidesToScroll: 2,
                },
                {
                    breakpoint: 992,
                    settings: 'unslick'
                }
            ]
        });

    $('.resources__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '13%',
        prevArrow,
        nextArrow,
        focusOnSelect: true,
        speed: 600,
        centerMode: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '0',
                    centerMode: false,
                    arrows: true
                }
            },
        ]
    });

    $('.resources__slider').on('afterChange', function (event, slick, currentSlide) {
        $('.resources__slide.slick-current').siblings().find('iframe').each(function () {
            $(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
        })
    })


    $('.whitepapers__grid')
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: true,
            arrows: true,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        dots: false
                    }
                },
                {
                    breakpoint: 1366,
                    settings: {
                        slidesToShow: 3,
                        dots: false
                    }
                }
            ]
        });

    $('.whitepapers__grid .slick-next').insertAfter('.whitepapers__grid .slick-dots')
    $('.whitepapers__grid .slick-next, .whitepapers__grid .slick-dots').wrapAll("<div class='slider-nav'>")


    $('.slider_dots').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $(this).find('.slick-dots li.slick-active').addClass('before-change')
    });

    $('.slider_dots').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $(this).find('.slick-dots li').removeClass('before-change')
    });

    $('.microgrant__gallery')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    if (slick.activeBreakpoint < window.innerWidth) {
                        return;
                    }
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: false,
            prevArrow,
            nextArrow,
            slidesToScroll: 1,
            initialSlide: 4,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });

    let prevArrowProject = `
    <button type="button" class="slick-arrow__prev">
       <svg xmlns="http://www.w3.org/2000/svg" width="15" height="42" viewBox="0 0 15 42">
        <path fill="none" fill-rule="evenodd" stroke="#000" d="M13.046 0L1 20.344 13.046 40.689" transform="translate(.036 .311)"/>
       </svg>
      <span>prev</span>
    </button>
  `;

    let nextArrowProject = `
    <button type="button" class="slick-arrow__next">
       <svg xmlns="http://www.w3.org/2000/svg" width="15" height="42" viewBox="0 0 15 42">
        <path fill="none" fill-rule="evenodd" stroke="#000" d="M13.046 0L1 20.344 13.046 40.689" transform="translate(.036 .311)"/>
       </svg>
      <span>prev</span>
    </button>
  `;

    $('.single-project__image-slider').slick({
        slidesToShow: 1,
        arrows: false,
        dots: false,
        speed: 600,
        asNavFor: '.single-project__gallery-slider',
    })


    $('.single-project__gallery-slider').slick({
        prevArrow: prevArrowProject,
        nextArrow: nextArrowProject,
        speed: 600,
        focusOnSelect: true,
        asNavFor: '.single-project__image-slider',
        centerMode: true,
        variableWidth: true,
        centerPadding: 28,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    centerMode: false,
                    centerPadding: 11,
                }
            }
        ]
    });


    $('.hero-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true
    });

    const quoteArrowPrev = `  <button class="arrow-next">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13">
          <path fill="none" fill-rule="evenodd" stroke="#E34D3C" stroke-linecap="round" stroke-width="2" d="M222.656 101L232.656 111 242.656 101" transform="translate(-221 -100)"/>
        </svg>
      </button>`

    const quoteArrowNext = `  <button class="arrow-prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13">
          <path fill="none" fill-rule="evenodd" stroke="#E34D3C" stroke-linecap="round" stroke-width="2" d="M222.656 101L232.656 111 242.656 101" transform="translate(-221 -100)"/>
        </svg>
      </button>`

    $('.quote-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        prevArrow: quoteArrowPrev,
        nextArrow: quoteArrowNext,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                }
            }
        ]
    });

    const indArrowNext = `  <div class="arrow-next-wrapper"><button class="arrow-next">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13">
          <path fill="none" fill-rule="evenodd" stroke="#E34D3C" stroke-linecap="round" stroke-width="2" d="M222.656 101L232.656 111 242.656 101" transform="translate(-221 -100)"/>
        </svg>
      </button>
      <span class="text">Next</span>
      <span class="heading"></span>
      </div>`

    const indArrowPrev = `<div class="arrow-prev-wrapper"><button class="arrow-prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" viewBox="0 0 23 13">
          <path fill="none" fill-rule="evenodd" stroke="#E34D3C" stroke-linecap="round" stroke-width="2" d="M222.656 101L232.656 111 242.656 101" transform="translate(-221 -100)"/>
        </svg>
      </button>
        <span class="text">Prev</span>
        <span class="heading"></span>
      </div>`

    $('.industries-projects-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        swipe: false
    });

    $('.industries-slider').on('init', function (e) {
        const prevTitle = $(this).find('.slick-active').prev().find('.h1').text();
        const nextTitle = $(this).find('.slick-active').next().find('.h1').text();
        $(this).find('.arrow-prev-wrapper .heading').html(prevTitle)
        $(this).find('.arrow-next-wrapper .heading').html(nextTitle)
    })


    $('.industries-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        prevArrow: indArrowPrev,
        nextArrow: indArrowNext,
        asNavFor: $('.industries-projects-slider'),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                    customPaging: function (slider, i) {
                        const title = $(slider.$slides[i].innerHTML).find('.h1').text()
                        return `<button>${title}</button>`
                    },
                }
            }
        ],
    });

    $('.industries-slider').on('afterChange', function (e) {
        const prevTitle = $(this).find('.slick-active').prev().find('.h1').text();
        const nextTitle = $(this).find('.slick-active').next().find('.h1').text();
        $(this).find('.arrow-prev-wrapper .heading').html(prevTitle)
        $(this).find('.arrow-next-wrapper .heading').html(nextTitle)
    })


    $('.works-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        prevArrow: '.works-slider-nav .arrow-prev',
        nextArrow: '.works-slider-nav .arrow-next',
        arrows: true,
        dots: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                }
            }
        ]
    });

    const socialMediaItems = $('#sbi_images > .sbi_item')
    if (window.innerWidth > 768) {
        for (let i = 0; i < socialMediaItems.length; i += 2) {
            socialMediaItems.slice(i, i + 2).wrapAll("<div class='slide'></div>");
        }
    } else {
        for (let i = 0; i < socialMediaItems.length; i++) {
            socialMediaItems.slice(i, i + 1).wrapAll("<div class='slide'></div>");
        }
    }

    $('.project-card-new .image-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
    });

    $('#sbi_images').slick({
        dots: false,
        arrows: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: true,
        // infinite: true,
        autoplay: false,
    });

    const categorySliders = $('.resources-category-slider')

    categorySliders.each((i, el) => {
        const $nav = $(el).next('.resources-category-nav')
        const prevArrow = $nav.find('.arrow-prev')
        const nextArrow = $nav.find('.arrow-next')

        $(el).on('init', function (event, slick) {
            changeNavTop(slick, 0, $nav)
        })

        const slick = $(el).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow,
            nextArrow,
            dots: true,
            appendDots: $nav.find('.custom-dots'),
            fade: true,
            speed: 1200,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        variableWidth: true,
                        fade: false,
                    }
                }
            ]
        });

        slick.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if (window.innerWidth > 768) {
                const slide = slick.$slides.get(nextSlide);
                const contentHeight = $(slide).find('.content').outerHeight()
                $nav.animate({top: contentHeight}, 300)
            }
        })

    })

    function changeNavTop(slick, slide, $el) {
        if (window.innerWidth > 768) {
            const slide = slick.$slides.get(slide);
            const contentHeight = $(slide).find('.content').outerHeight()
            $el.animate({top: contentHeight}, 300)
        }
    }


    $('.industries-grid')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    if (slick.activeBreakpoint < window.innerWidth) {
                        return;
                    }
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            dots: true,
            arrows: false,
            slidesToScroll: 1,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });


    const projectGalleryWrappers = $('.projects-gallery-slider-wrapper')

    projectGalleryWrappers.each((i, wrapper) => {
        const $nav = $(wrapper).find('.projects-gallery-slider-nav')
        const prevArrow = $nav.find('.arrow-prev')
        const nextArrow = $nav.find('.arrow-next')
        const $slider = $(wrapper).find('.projects-gallery-slider')

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow,
            nextArrow,
            dots: true,
            appendDots: $nav.find('.custom-dots')
        });
    })


    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    function getRandomInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

})()