(function () {
    $('.hero__slider').slick({
        slidesToShow: 1,
        fade: true,
        arrow: true,
        dots: true
    });

    if ($('.hero__slider').length) {
        const $heroCounter = $('.hero__slider-counter');
        const $heroCounterTotal = $('.hero__slider-counter .total');
        const $heroCounterCount =  $('.hero__slider-counter .count');

        $heroCounterTotal.text(`0${$('.hero__slider .slick-slide').length}`)
        $heroCounterCount.text(`0${$('.hero__slider').slick('slickCurrentSlide') + 1}`)

        let heroCounterLeft
        let heroCounterRight

        setArrowsPosition()

        $('.hero__slider').on('afterChange', function () {
            $heroCounterCount.text(`0${$('.hero__slider').slick('slickCurrentSlide') + 1}`)
            setArrowsPosition()
        })

        $(window).on('resize', function () {
            setArrowsPosition()
        })


        function setArrowsPosition() {
            heroCounterLeft = $heroCounter.offset().left
            heroCounterRight = $heroCounter.offset().left + $heroCounter.innerWidth() - 10
            $('.hero__slider .slick-prev').css({left : heroCounterLeft})
            $('.hero__slider .slick-next').css({left : heroCounterRight})
        }
    }


})()