(function () {

  let $title = $('.single-project .accent-title');

  if ($title.length > 0) {

    let title = $('.single-project .accent-title').html();
    let firstLine = title.substr(0, title.indexOf('<'));
    if (firstLine.length >= 16) {
      $title.css({fontSize: 9.75 + 'rem'})
    }

    $('.single-project__data-list .single-project__show-more').on('click', function (e) {
      e.preventDefault();
      if (!$(this).hasClass('full-text')) {
        $('.single-project__data-list-inner').addClass('full-text');
        $(this).addClass('full-text').text('show less');
        setTimeout(function () {
          document.body.style.height = `${document.querySelector('div[data-scroll]').scrollHeight}px`;
        }, 500)
      } else {
        $('.single-project__data-list-inner').removeClass('full-text');
        $(this).removeClass('full-text').text('show more');
        setTimeout(function () {
          document.body.style.height = `${document.querySelector('div[data-scroll]').scrollHeight}px`;
        }, 500)
      }
    })
    $('.single-project__intro-text .single-project__show-more').on('click', function (e) {
      e.preventDefault();
      if (!$(this).hasClass('full-text')) {
        $('.single-project__intro-text-inner').addClass('full-text');
        $(this).addClass('full-text').text('show less');
        setTimeout(function () {
          document.body.style.height = `${document.querySelector('div[data-scroll]').scrollHeight}px`;
        }, 500)
      } else {
        $('.single-project__intro-text-inner').removeClass('full-text');
        $(this).removeClass('full-text').text('show more');
        setTimeout(function () {
          document.body.style.height = `${document.querySelector('div[data-scroll]').scrollHeight}px`;
        }, 500)
      }
    })
  }

})()