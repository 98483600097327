(function () {
  if ($('.contact__forms').length) {
    let form = $('.contact__forms form');
    $('.contact__forms').find('style').remove();
    form.addClass('form_touch-border form_touch form');
    form.find('._field-wrapper').addClass('form__field');
    $('.contact__forms form ._field-wrapper').each(function () {
      $(this).prev().appendTo(this)
    })
    $('._form_15 ._form-content > ._form_element:nth-child(5),' +
      ' ._form_15 ._form-content > ._form_element:nth-child(6),' +
      ' ._form_15 ._form-content > ._form_element:nth-child(7),' +
      ' ._form_15 ._button-wrapper').wrapAll('<div class="form__column"></div>')
    $('._form_15 ._form-content > ._form_element:nth-child(2),' +
      ' ._form_15 ._form-content > ._form_element:nth-child(3),' +
      ' ._form_15 ._form-content > ._form_element:nth-child(4)').wrapAll('<div class="form__column"></div>');
    $('._form_15 .form__column').wrapAll('<div class="df"></div>')
    $('._form_15 ._button-wrapper').wrap('<div class="form__field"></div>')
  }

  $('form input[name="email"]').on('input', function (e) {
    if ($(this).closest('form').hasClass('form_touch')) {
      let email = this;
      let value = $(email).val();
      let error = $(this).closest('.form__field').find('._error');
      let submitButton = $(this).closest('form').find('._submit')[0]
      let freeEmails = ['hotmail',
        'outlook',
        'yahoo',
        'zohomail',
        'mail.ru',
        'aol',
        'icloud',
        'yandex',
        'me.com',
        'msn.com',
        'mac.com'];
      let notBusinessEmail = freeEmails.some(elem => value.indexOf(elem) > 0)
      if (notBusinessEmail) {
        if (!error.length) {
          var tooltip = document.createElement('div'), arrow = document.createElement('div'),
            inner = document.createElement('div'), new_tooltip = {};
          email.className = email.className + ' _has_error';
          tooltip.className = '_error';
          arrow.className = '_error-arrow';
          inner.className = '_error-inner';
          inner.innerHTML = 'Looks like this is a personal email address. Please enter a business email address to submit.';
          tooltip.appendChild(arrow);
          tooltip.appendChild(inner);
          email.parentNode.appendChild(tooltip);
          new_tooltip.tip = tooltip;
          new_tooltip.elem = email;
          resize_tooltip(new_tooltip)
        }
        submitButton.disabled = true;
      } else {
        if (error.length) {
          error.remove();
          email.classList.remove('_has_error')
        }
        submitButton.disabled = false
      }
    }
  })

  var resize_tooltip = function (tooltip) {
    var rect = tooltip.elem.getBoundingClientRect();
    var doc = document.documentElement,
      scrollPosition = rect.top - ((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0));
    if (scrollPosition < 40) {
      tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _below';
    } else {
      tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _above';
    }
  };

})()