(function () {
  $(".form-popup__close").on('click', function (e) {
    e.preventDefault();
    $(this).closest('.form-popup').addClass('hidden');
    bodyScrollLock.clearAllBodyScrollLocks();
  })


  const $contactButton = $('.contact-button');
  const $touchPopup = $('#touch-popup');

  $contactButton.on('click', function (e) {
    e.preventDefault();
    $touchPopup.removeClass('hidden');
    bodyScrollLock.disableBodyScroll($touchPopup[0]);
  })


})()