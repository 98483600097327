(function () {

    if ($('.section-circle-parallax').length) {
        setParallax();

        $(window).on("scroll", function() {
            setParallax()
        });
    }

    function setParallax() {
        let sectionTop = $('.section-circle-parallax').offset().top;
        let layers = $(".circle-parallax__layer");
        let top = $(window).scrollTop();
        let sectionScroll = top - sectionTop + $(window).height()
        let speed, yPos;
        if (sectionScroll >= 0) {
            layers.each(function () {
                speed = $(this).attr('data-speed');
                yPos = -(top * speed / 100);
                $(this).attr('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px)');
            });
        }
    }
})()