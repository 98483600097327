(function () {

  $(".custom-select").select2({
    minimumResultsForSearch: -1,
    allowClear: true
  });

  let $search = $('.form_search input[type="text"]')
  if ($search.length > 0) {
    if ($search.val().length > 0) {
      $search.addClass('active')
    }
  }

  $('form input:not([type="submit"]), form textarea').on('change', function () {
    if ($(this).val().length > 0) {
      $(this).addClass('active')
    } else {
      $(this).removeClass('active')
    }
  })

  if ($('.consultation__form').length) {
    $('.consultation__form form').addClass('form_touch-border form_touch form');
    $('.consultation__form form ._field-wrapper').addClass('form__field');
    $('.consultation__form form ._field-wrapper').each(function () {
      $(this).prev().appendTo(this)
    })
    $('._form_21 ._form-content > ._form_element:nth-child(6),' +
      ' ._form_21 ._form-content > ._form_element:nth-child(7),' +
      ' ._form_21 ._button-wrapper').wrapAll('<div class="form__column"></div>')
    $('._form_21 ._form-content > ._form_element:nth-child(3),' +
      ' ._form_21 ._form-content > ._form_element:nth-child(4),' +
      ' ._form_21 ._form-content > ._form_element:nth-child(5)').wrapAll('<div class="form__column"></div>');
    $('._form_21 .form__column').wrapAll('<div class="df"></div>')
    $('._form_21 ._button-wrapper').wrap('<div class="form__field"></div>')
  }

  if ( $('.download-whitepaper').length) {
    $('.download-whitepaper form').addClass('form form_download');
    $('.download-whitepaper form ._field-wrapper').addClass('form__field');
    $('.download-whitepaper form ._field-wrapper').each(function () {
      $(this).prev().appendTo(this)
    })
  }

  $('form').each(function () {
    let form = $(this);
    if ($(this).find('._checkbox-radio').length) {
      form.find('._form-fieldset').addClass('form__field').append('<div class="custom-radio-group"></div>')
      let checkbox = $(this).find('._row._checkbox-radio')
      checkbox.each(function (i) {
        let label = $(this).find('label').text();
        let input = $(this).find('input');
        form.find('.custom-radio-group').append(`<label class="custom-radio _form-label"><span>${label}</span></label>`)
        form.find('.custom-radio').eq(i).prepend(input)
        $(this).remove();
      })
    }
  })


  // $('.form_touch').on('submit', function (e) {
  //     e.preventDefault();
  //     if (validateForm(e)) {
  //         send(e, `${wp.tplUrl}/send.php`);
  //         let inputs = this.querySelectorAll('input, textarea');
  //         for (let input of inputs) {
  //             input.value = null;
  //             input.classList.remove('active')
  //         }
  //         $('input.required').removeClass('has-error');
  //         this.querySelector('.message').classList.remove('hidden')
  //     }
  // })

  function send(event, php) {
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
    console.log("Sending message");
    let message = event.target.querySelector('.message')
    let req = new XMLHttpRequest();
    req.open('POST', php, true);
    req.onload = function () {
      if (req.status >= 200 && req.status < 400) {
        let json = JSON.parse(this.response);
        if (json.result == "success") {
          console.log("Message has been sent");
          message.textContent = 'Thank you! Message has been sent.'
        } else {
          console.log("Error. Message hasn't been sent");
          message.textContent = 'Something went wrong. Try again'
        }
      } else {
        console.log("Server error. Number: " + req.status);
        message.textContent = 'Something went wrong. Try again'
      }
    };

    req.onerror = function () {
      alert("Request error");
    };
    req.send(new FormData(event.target));
  }

  function validateForm(e) {
    let validate = true;
    validate = validate &&
      isRequiredFieldsFill(e)
    let emailField = e.target.email
    if (emailField) {
      validate = validate && isValidEmail(e)
    }
    return validate;
  }

  function isRequiredFieldsFill(e) {
    let isRequiredFill = true;
    $('.error').remove();
    $('input.required').removeClass('has-error');
    let requiredFields = e.target.querySelectorAll('input.required');
    for (let field of requiredFields) {
      let value = field.value
      if (!value.length) {
        let label = field.nextElementSibling;
        field.classList.add('has-error')
        label.insertAdjacentHTML("afterend", '<div class="error">This field is required.</div>')
        isRequiredFill = isRequiredFill && false;
      }
    }
    return isRequiredFill
  }

  function isValidEmail(e) {
    let emailField = e.target.email;
    let email = emailField.value;
    let pattern = new RegExp(/.+@.+\..+/i);
    if (!pattern.test(email)) {
      let label = emailField.nextElementSibling;
      label.insertAdjacentHTML('afterend', '<span class="error">Email is incorrect</span>')
      emailField.classList.add('has-error')
      return false
    } else {
      let freeEmails = ['hotmail',
        'outlook',
        'yahoo',
        'zohomail',
        'mail.ru',
        'aol',
        'icloud',
        'yandex',
        'me.com',
        'msn.com',
        'mac.com'];
      let notBusinessEmail = freeEmails.some(elem => email.indexOf(elem) > 0)
      if (notBusinessEmail) {
        document.querySelector('.form_touch').classList.remove('form_touch-error');
        document.querySelector('.form_touch .form__field_email').classList.remove('form__field_error');
        return true
      } else {
        document.querySelector('.form_touch').classList.add('form_touch-error');
        document.querySelector('.form_touch .form__field_email').classList.add('form__field_error');
        return false
      }
    }
  }


})()