(function () {
    $('.person__button').on('click', function (e) {
        let $person = $(this).closest('.person')
        let image = $person.attr('data-image');
        let name = $person.find('.person__name').text();
        let position = $person.find('.person__position').text();
        let text = $person.find('.person__text').html();
        let link = $person.find('.person__link').attr('href')
        $('.team-popup__image img').attr('src', image);
        $('.team-popup__name').text(name);
        $('.team-popup__position').text(position)
        $('.team-popup__text').html(text);
        $('.team-popup__link').attr('href', link)
        $('.team-popup').addClass('opened')
    })

    let hash = location.hash;
    let path = location.pathname;

    if (path.indexOf('about') != -1 && !!path.length) {
        $('.person__button[href="' + hash + '"]').trigger('click')
    }

    $('.team-popup__close').on('click', function (e) {
        e.preventDefault();
        $('.team-popup').removeClass('opened')
        $('.team-popup__image img').attr('src', ' ');
        $('.team-popup__name').text(' ');
        $('.team-popup__position').text(' ')
        $('.team-popup__text').html(' ');
        $('.team-popup__link').attr('href', '')
    })
})()