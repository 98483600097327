(function () {
    let xl = $(window).width() > 1366;
    let lg = $(window).width() > 992;
    let md = $(window).width() > 768;

    if (lg) {
        $('.about-intro h4').prependTo('.about-intro__content');
        $('.difference h2').prependTo('.difference__content');
        $('.vision h2').prependTo('.vision__content');
        $('.single-project__image').prependTo('.single-project__visual');
        $('.featured-post-card__data').prependTo('.featured-post-card__content');
    }

    if (!lg) {
        let projectsSliderHeight = $('.project-card__gallery').height();
        $('.projects__slider .slider-nav').css({top: projectsSliderHeight})
        $('.work-featured__grid .slick-next').insertAfter('.work-featured__grid .slick-dots')
        $('.work-featured__grid .slick-next, .work-featured__grid .slick-dots').wrapAll("<div class='slider-nav'>")
    }

    if (!md) {
        $('.team__leaders .person__position').each(function () {
            let person = $(this).parents('.team__grid-item')
            $(this).prependTo(person)
        })
    }

    $(window).on('resize', () => {
        xl = $(window).width() > 1366;
        lg = $(window).width() > 992;
        md = $(window).width() > 768;
        if (lg) {
            if (!$('.about-intro__content > h4').length) {
                $('.about-intro h4').prependTo('.about-intro__content')
            }
            if (!$('.difference__content > h2').length) {
                $('.difference h2').prependTo('.difference__content');
            }
            if (!$('.vision__content > h2').length) {
                $('.vision h2').prependTo('.vision__content')
            }
            if (!$('.featured-post-card__content > .featured-post-card__data').length) {
                $('.featured-post-card__data').prependTo('.featured-post-card__content')
            }
            if (!$('.single-project__visual > .single-project__image').length) {
                $('.single-project__image').prependTo('.single-project__visual');
            }
        } else {
            if (!$('.about-intro__inner > h4').length) {
                $('.about-intro h4').insertAfter('.about-intro h2')
            }
            if (!$('.difference__inner > h2').length) {
                $('.difference h2').prependTo('.difference__inner');
            }
            if (!$('.vision__inner > h2').length) {
                $('.vision h2').prependTo('.vision__inner')
            }
            if (!$('.single-project__intro-content > .single-project__image').length) {
                $('.single-project__image').insertBefore('.single-project__intro-content .accent-title')
            }
            if (!$('.work-featured__grid .slider-nav').length) {
                $('.work-featured__grid .slick-next').insertAfter('.work-featured__grid .slick-dots')
                $('.work-featured__grid .slick-next, .work-featured__grid .slick-dots').wrapAll("<div class='slider-nav'>")
            }
            if (!$('.featured-post-card__content-inner > .featured-post-card__data').length) {
                $('.featured-post-card__data').insertAfter('.featured-post-card__title')
            }

            let projectsSliderHeight = $('.project-card__gallery').height();
            $('.projects__slider .slider-nav').css({top: projectsSliderHeight})

        }
    })
})()