(function () {
    const $header = $('.header');
    const $menu = $('.header__menu');
    const $menuInner = $('.header__menu-inner');
    const $hamburger = $('.hamburger-btn');
    let isMenuOpened = false;

    // $hamburger.on('click', function () {
    //     $(this).toggleClass('opened');
    //     $menu.toggleClass('opened');
    //     isMenuOpened = !isMenuOpened;
    //     if (isMenuOpened) {
    //         $header.toggleClass('menu-opened');
    //         setTimeout(function () {
    //             $menuInner.toggleClass('visible')
    //         }, 600)
    //         bodyScrollLock.disableBodyScroll($menu[0]);
    //     } else {
    //         $menuInner.toggleClass('visible')
    //         setTimeout(function () {
    //             $header.toggleClass('menu-opened');
    //         }, 800)
    //         bodyScrollLock.clearAllBodyScrollLocks();
    //     }
    // });
    //
    // $(document).click(function (e) {
    //     if ($menu.hasClass('opened')) {
    //         if ((!$(e.target).closest('.header__menu').length) && (!$(e.target).closest('.hamburger-btn').length)) {
    //             $hamburger.removeClass('opened');
    //             $menu.removeClass('opened');
    //             $menuInner.toggleClass('visible')
    //             setTimeout(function () {
    //                 $header.removeClass('menu-opened');
    //             }, 800)
    //             isMenuOpened = !isMenuOpened;
    //             bodyScrollLock.clearAllBodyScrollLocks();
    //         }
    //     }
    // });
    // let scrollPos = 0;
    // let scrollDown = true;
    // let st = $(window).scrollTop();
    //
    // if ($(window).width() > 1366) {
    //     if (scrollDown && st > 300) {
    //         $('.header__logo').addClass('hidden')
    //     }
    //     if (!scrollDown) {
    //         $('.header__logo').removeClass('hidden')
    //     }
    // }
    // $(window).on('scroll', () => {
    //     if ($(window).width() > 1366) {
    //         st = $(window).scrollTop();
    //         scrollDown = st > scrollPos;
    //         scrollPos = st;
    //         if (scrollDown && st > 300) {
    //             $('.header__logo').addClass('hidden')
    //         }
    //         if (!scrollDown) {
    //             $('.header__logo').removeClass('hidden')
    //         }
    //     }
    // })
    //
    // $(window).on('resize', function () {
    //     if ($(window).width() > 1366) {
    //         if (scrollDown && st > 300) {
    //             $('.header__logo').addClass('hidden')
    //         }
    //         if (!scrollDown) {
    //             $('.header__logo').removeClass('hidden')
    //         }
    //     }
    // })

    const subMenuItem = $('.main-menu .sub-menu > .menu-item-has-children')
    const $mainMenu = $('.main-nav')

    subMenuItem.on('click', function (e) {
        if (!$(this).hasClass('active')) {
            e.preventDefault();
            e.stopPropagation()
            $(this).addClass('active').siblings().removeClass('active')
        }
    })

    const menuOpener = $('.mobile-menu-opener')
    menuOpener.on('click', function () {
        $mainMenu.addClass('opened')
        isMenuOpened = true
        $header.addClass('menu-opened');
        bodyScrollLock.disableBodyScroll($mainMenu[0]);
    })

    const closeMenu = $('.main-nav .menu-close')

    closeMenu.on('click', function () {
        $mainMenu.removeClass('opened')
        isMenuOpened = false
        $header.removeClass('menu-opened');
        bodyScrollLock.clearAllBodyScrollLocks();
    })

    const imgMenuDiv = $('.sub-menu-div')

    imgMenuDiv.each((index, item) => {

        const wrapper = $(item).find('.sub-menu-wrapper')
        const nextArrow = $(item).find('.menu-scroll-button-next')
        const prevArrow = $(item).find('.menu-scroll-button-prev')
        const width = wrapper.width()
        const scrollWidth = wrapper[0].scrollWidth

        nextArrow.on('click', function () {
            wrapper.scrollLeft(scrollWidth - width)
        })

        prevArrow.on('click', function () {
            wrapper.scrollLeft(0)
        })

        $(item).addClass('left-limit')

        if (width < $(item).width()) {
            $(item).addClass('right-limit')
        }

        wrapper.on('scroll', function (e) {
            let scrollLeft = $(this).scrollLeft()
            if (scrollLeft === 0) {
                $(item).addClass('left-limit')
            } else {
                $(item).removeClass('left-limit')
            }
            if ($(this).width() + scrollLeft >= this.scrollWidth - 3) {
                $(item).addClass('right-limit')
            } else {
                $(item).removeClass('right-limit')
            }
        })
    })

    $(window).on(
        'resize',
        debounce(function () {
            if (isMenuOpened && window.innerWidth >= 768) {
                bodyScrollLock.clearAllBodyScrollLocks();
            }
        })
    );

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
})();
