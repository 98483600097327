(function() {
    $('.press-by-year').each(function() {
        let pressList = $(this).find('.press__list');
        let pressListLength = pressList.find('article').length;
        if (pressListLength > 5) {
            pressList.after('<button class="button press__button aos-init aos-animate" data-aos="fade-up">Show More</button>')
            for (let i = 5; i <= pressListLength; i++) {
                pressList.find('article').eq(i).css({ display: 'none' });
            }
        }
    })

    $(document).on('click', '.press__button', function() {
        $(this).prev('.press__list').find('article').slideDown()
        $(this).remove();
        setTimeout(function() {
            document.body.style.height = `${document.querySelector('div[data-scroll]').scrollHeight}px`;
        }, 450)
    })
})()