(function () {

    let $grid = $('.masonry__gallery').imagesLoaded(function () {
        if ($(window).width() >= 768) {
            $grid.masonry({
                itemSelector: '.masonry__gallery-item',
                columnWidth: '.masonry__gallery-item',
                percentPosition: true
            });
        }
    });

    $(window).on('resize', function () {
        if ($(window).width() < 768) {
            $grid.masonry('destroy')
            setTimeout(function () {
                document.body.style.height = `${document.querySelector('div[data-scroll]').scrollHeight}px`;
            }, 1000)
        }
    })
})()