(function () {
  if ($('.projects-list__filters').length) {
    let windowWidth = $(window).width();

    $(window).on('resize', function () {
      windowWidth = $(window).width();
    })

    let $filters = $('.projects-list__filters');
    let filtersTop = $filters.offset().top;
    $(window).on('scroll', function () {
      if (windowWidth > 1366) {
        let st = $(window).scrollTop();
        if (st > filtersTop) {
          $filters.addClass('sticky').insertBefore('.main-wrapper');
          $filters.find('p').slideUp(300);
          $('.projects-list__control-panel').addClass('withPadding')
        } else {
          $filters.find('p').slideDown(300);
          $filters.removeClass('sticky').insertBefore('.projects-list__controls')
          $('.projects-list__control-panel').removeClass('withPadding')
        }
      }
    })
  }
})()