(function () {
   const videoBlocks = $('.video')

   videoBlocks.each((i, block) => {
       const iframe = $(block).find('iframe')[0]
       const overlay = $(block).find('.overlay')
       const button = $(block).find('.play')
       button.on('click', function () {
           iframe.src += "?autoplay=1";
           overlay.fadeOut(1000)
       })
   })

    const resourceBlocks = $('.resources-category-item--video')

    resourceBlocks.each((i, block) => {
        const playButton = $(block).find('.play')
        const video = $(block).closest('.resources-category-wrapper--video').find('.video-popup')
        const iframe = $(video).find('iframe')[0]
        const close = $(video).find('.close')

        if (playButton) {
            playButton.on('click', function () {
                const link = $(this).attr('data-video')
                iframe.src = link + "?autoplay=1";
                video.addClass('opened');
                bodyScrollLock.disableBodyScroll(video);
            })

            close.on('click', function () {
                video.removeClass('opened');
                iframe.src = "";
                // iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
                bodyScrollLock.clearAllBodyScrollLocks();
            })
        }
    })
})()