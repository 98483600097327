import simpleParallax from 'simple-parallax-js';
import {fixSizes} from "./equalizeHeight";


$(document).ready(() => {
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  // require('./cursor.js');
  require('./gallery');
  require('./circle-parallax');
  require('./menu.js');
  require('./hero.js');
  require('./sliders.js');
  require('./media.js');
  require('./team.js');
  require('./single-project.js');
  require('./forms.js');
  require('./vimeo.js');
  // require('./map.js');
  require('./text-scroll.js');
  // require('./smooth-scroll.js');
  require('./videoButton');
  require('./press.js');
  require('./project-filters');
  require('./popup');
  require('./contact-form')
  require('./header')
  require('./video')
  fixSizes();
});

// remove preloader
$(window).on('load', () => {
  let session = sessionStorage.getItem('session');
  if (session) {
    $('.preloader').delay(800).fadeOut();
  } else {
    session = sessionStorage.setItem('session', true);
    $('.preloader').delay(2600).fadeOut();
  }


  $('body').removeClass('loading');
  $('html').addClass('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart',

  });

  const images = document.querySelectorAll('.image-parallax');
  new simpleParallax(images);

  let containers = document.querySelectorAll('.tab-container')
  containers.forEach(el => {
    tabs(el);
  })

  AOS.refresh();

});
